import { Validator } from '../../types';

const buildValidators = (validators: Validator[]) => {
    let validate = {};
    if (validators && validators.length > 0) {
        validators.forEach((validator: Validator) => {
            validate = { ...validate, ...validator };
        });
    }
    return validate;
};

export default buildValidators;
