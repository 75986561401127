/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { CubedFormContextProvider, FormStatus } from './context/form-context';

// Form Components
import FormBody from './form-body';
import FormFooter from './form-footer';
import FormSection from './form-section';
import FormSectionTitle from './form-section-title';
import FormField from './form-field';

// Inputs
import InputLabel from './inputs/input-label';
import InputText from './inputs/input-text';
import InputButton from './inputs/input-button';
import InputTextArea from './inputs/input-text-area';
import InputCheckbox from './inputs/input-checkbox';
import InputNumber from './inputs/input-number';
import InputToggle from './inputs/input-toggle';
import InputEmail from './inputs/input-email';
import InputPassword from './inputs/input-password';
import InputFileUpload from './inputs/input-file-upload';
import InputDatePicker from './inputs/input-date-picker';
import InputSelect from './inputs/input-select';
import InputColourPicker from './inputs/input-colour-picker';
import InputTag from './inputs/input-tag';

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export type FormProps = {
    defaultValues?: FieldValues;
    onSubmit: (data: FieldValues) => void;
    children: React.ReactNode;
    status?: FormStatus;
    onWatchFieldsChange?: (fields: FieldValues) => void;
};

const Form = ({ status, defaultValues, onSubmit, onWatchFieldsChange, children }: FormProps) => {
    const methods = useForm<FieldValues>({ defaultValues });

    useEffect(() => {
        if (onWatchFieldsChange) {
            const subscription = methods.watch(value => onWatchFieldsChange(value));
            return () => subscription.unsubscribe();
        }
    }, [methods.watch]);

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues]);

    return (
        <CubedFormContextProvider status={status} defaultValues={defaultValues}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <StyledFormContainer>{children}</StyledFormContainer>
                </form>
            </FormProvider>
        </CubedFormContextProvider>
    );
};

// Form Components
Form.Body = FormBody;
Form.Footer = FormFooter;
Form.Section = FormSection;
Form.SectionTitle = FormSectionTitle;
Form.Field = FormField;

// Inputs
Form.InputLabel = InputLabel;
Form.InputText = InputText;
Form.InputButton = InputButton;
Form.InputTextArea = InputTextArea;
Form.InputCheckbox = InputCheckbox;
Form.InputNumber = InputNumber;
Form.InputToggle = InputToggle;
Form.InputEmail = InputEmail;
Form.InputPassword = InputPassword;
Form.InputFileUpload = InputFileUpload;
Form.InputDatePicker = InputDatePicker;
Form.InputSelect = InputSelect;
Form.InputColourPicker = InputColourPicker;
Form.InputTag = InputTag;

export default Form;
