import { CubedConfigResource } from '../types';

export const CONFIG_DASH_REFERER: CubedConfigResource = {
    category: 'config',
    id: 'dash-referer',
};

export const CONFIG_GOALS: CubedConfigResource = {
    category: 'config',
    id: 'product',
};

export const CONFIG_CHANNELS: CubedConfigResource = {
    category: 'config',
    id: 'referer',
};

export const CONFIG_SUBCHANNELS: CubedConfigResource = {
    category: 'config',
    id: 'subchannel',
};

export const PATH_SUBDIRECTORY_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'path-subdirectory-config',
};

export const CONFIG_PATTERN: CubedConfigResource = {
    category: 'config',
    id: 'pattern',
};

export const PATH_CATEGORY_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'path-category-config',
};

export const EXCLUDE_DATES_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'exclude-dates',
};

export const REALLOCATION_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'reallocation-config',
};

export const CONFIG_ACCOUNT_USER: CubedConfigResource = {
    category: 'config',
    id: 'account-user',
};

export const GEOLOCATION_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'geolocation',
};

export const CONFIG_EXCLUDE_DATES: CubedConfigResource = {
    category: 'config',
    id: 'exclude-dates',
};

export const CONFIG_DOMAINS: CubedConfigResource = {
    category: 'config',
    id: 'domains',
};

export const ACCOUNT_SETUP_REQUEST: CubedConfigResource = {
    category: 'account',
    id: 'account-setup-request',
};

export const CONFIG_SEOGD_MARKET: CubedConfigResource = {
    category: 'config',
    id: 'seogd-market',
};

export const CONFIG_SEOGD_COMPETITOR: CubedConfigResource = {
    category: 'config',
    id: 'seogd-competitor-config',
};

export const CONFIG_KWR_BLACKLISTED_KEYWORD: CubedConfigResource = {
    category: 'config',
    id: 'kwr-blacklisted-keyword',
};

export const CONFIG_BROWSER: CubedConfigResource = {
    category: 'config',
    id: 'browser',
};

export const CONFIG_DEVICE: CubedConfigResource = {
    category: 'config',
    id: 'device',
};

export const CONFIG_BLACKLIST_LEVEL: CubedConfigResource = {
    category: 'config',
    id: 'blacklist-level',
};

export const CONFIG_BLACKLIST_IP: CubedConfigResource = {
    category: 'config',
    id: 'blacklist-ip',
};

export const CONFIG_BLACKLIST_VISITOR: CubedConfigResource = {
    category: 'config',
    id: 'blacklist-visitor',
};

export const CONFIG_EVENT: CubedConfigResource = {
    category: 'config',
    id: 'event',
};

export const CONFIG_PATH_CATEGORY: CubedConfigResource = {
    category: 'config',
    id: 'path_category',
};

export const CONFIG_DASH_PATH: CubedConfigResource = {
    category: 'config',
    id: 'dash-path',
};

export const CSV_UPLOAD_RESOURCE: CubedConfigResource = {
    category: 'upload',
    id: 'csv-importer',
};

export const PAGE_SEGMENTS_FILE_UPLOAD_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'page-segments-upload-config',
};

export const FORECAST_FILE_UPLOAD_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'forecast-upload-config',
};

export const CONFIG_SUBCHANNEL_RULES_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'segmenter-subchannel',
};

export const CONFIG_CONDITION_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'segmenter-condition',
};

export const SUBCHANNEL_RULES_RESOURCE: CubedConfigResource = {
    category: 'segmenter',
    id: 'segmenter-subchannel',
};

export const CONFIG_PAGE_SEGMENTS_CSV_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'page-segments-csv',
};

export const CONFIG_PAGE_SEGMENTS_URLS_RESOURCE: CubedConfigResource = {
    category: 'config',
    id: 'page-segments-urls-lite',
};

export const CONFIG_AUTO_EMAIL_PAGE: CubedConfigResource = {
    category: 'config',
    id: 'auto-email-page',
};
export const CONFIG_AUTO_EMAIL_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'auto-email-config',
};
export const CONFIG_AUTO_EMAIL_RECIPIENTS: CubedConfigResource = {
    category: 'config',
    id: 'auto-email-recipients',
};

export const CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS: CubedConfigResource = {
    category: 'config',
    id: 'auto-email-config-email-recipients',
};

export const CONFIG_AUTO_EMAIL_CONFIG_CHANNEL: CubedConfigResource = {
    category: 'config',
    id: 'auto-email-config-channel',
};

export const CONFIG_TIMEZONE_CURRENCY: CubedConfigResource = {
    category: 'config',
    id: 'timezone-currency',
};

export const CONFIG_TIMEZONE: CubedConfigResource = {
    category: 'config',
    id: 'timezone',
};

export const CONFIG_CURRENCY: CubedConfigResource = {
    category: 'config',
    id: 'currency',
};

export const CONFIG_PRODUCT_TYPE: CubedConfigResource = {
    category: 'config',
    id: 'product-type',
};

export const CONFIG_MARKET: CubedConfigResource = {
    category: 'config',
    id: 'product-market',
};

export const CONFIG_LITE_EVENT: CubedConfigResource = {
    category: 'config',
    id: 'lite-event',
};

export const CONFIG_LITE_PRODUCT_EVENT: CubedConfigResource = {
    category: 'config',
    id: 'lite-product-event',
};

export const CONFIG_PRODUCT_EVENT: CubedConfigResource = {
    category: 'config',
    id: 'product-event',
};

export const CONFIG_PRODUCT_LOOKBACK: CubedConfigResource = {
    category: 'config',
    id: 'product-lookback',
};

export const CONFIG_LITE_FIXED_COST: CubedConfigResource = {
    category: 'config',
    id: 'lite-fixed-cost',
};

export const CONFIG_FIXED_COST: CubedConfigResource = {
    category: 'config',
    id: 'fixed-cost',
};

export const CONFIG_GROUPS: CubedConfigResource = {
    category: 'config',
    id: 'groups',
};

export const CONFIG_USERS: CubedConfigResource = {
    category: 'config',
    id: 'users',
};

export const CONFIG_INVITE: CubedConfigResource = {
    category: 'users',
    id: 'invite',
};

export const CONFIG_INVITE_EXISTING: CubedConfigResource = {
    category: 'users',
    id: 'invite-existing',
};

export const CONFIG_KEYWORD_TYPE: CubedConfigResource = {
    category: 'config',
    id: 'keyword-type',
};

export const CONFIG_BRAND_SPLIT_CONFIG: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-config',
};

export const CONFIG_BRAND_SPLIT_SECTOR: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-sector',
};

export const CONFIG_BRAND_SPLIT_PURCHASED: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-purchased',
};

export const CONFIG_BRAND_SPLIT_PRICE: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-price',
};

export const CONFIG_BRAND_SPLIT_INNOVATION: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-innovation',
};

export const CONFIG_BRAND_SPLIT_CATEGORY: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-category',
};

export const CONFIG_BRAND_SPLIT_SIZE: CubedConfigResource = {
    category: 'config',
    id: 'brand-split-size',
};
