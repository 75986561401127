/* eslint-disable react-hooks/exhaustive-deps */

// React Dependencies
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Core Dependencies
import AnimeJS from 'animejs';

// Redux Actions
import { setModal } from '../redux/actions/modal';
import { setPopup, removePopup } from '../redux/actions/popup';
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

// Import Components
import List from '../components/list';
import SidebarMenu from '../components/sidebar-menu';
import SearchBreadcrumbsWidget from '../breadcrumbs/widgets/search-breadcrumbs';
import { useLocation } from 'react-router-dom';
import { setDescription } from '../redux/actions/page-meta';

const LayoutAccountConfiguration = () => {
    const { account } = useSelector(state => state);

    const dispatch = useDispatch();
    const location = useLocation();

    const [prevItem, setPrevItem] = useState();
    const [listErrorMessageOverride, setListErrorMessageOverride] = useState('');

    const [visibleAccountConfigurations, setVisibleAccountConfigurations] = useState([]);
    const [allAccountConfigurations, setAllAccountConfigurations] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(setDescription(''));
    }, []);

    const listComponentOnClickHandler = useCallback(
        e => {
            switch (e.currentTarget.getAttribute('data-key')) {
                case 'integrations_google-api':
                    dispatch(setModal('Unified-googleAPI', {}));
                    break;
                case 'integrations_facebook-api':
                    dispatch(setModal('FacebookAPI', {}));
                    break;
                case 'integrations_bing-api':
                    dispatch(setModal('BingAPI', {}));
                    break;
                case 'integrations_manage-klaviyo':
                    dispatch(setModal('ManageKlaviyo', {}));
                    break;
                case 'integrations_manage-pinterest':
                    dispatch(setModal('PinterestAPI', {}));
                    break;
                case 'integrations_manage-semrush':
                    dispatch(setModal('SemrushAPI', {}));
                    break;
                case 'blacklisting_manage-ip-blacklist':
                    dispatch(setModal('ManageIpBlacklist', {}));
                    break;
                case 'blacklisting_manage-visitor-blacklist':
                    dispatch(setModal('ManageVisitorBlacklist', {}));
                    break;
                case 'channels_manage-channels':
                    dispatch(setModal('ManageChannels', {}));
                    break;
                case 'domains_manage-domains':
                    dispatch(setModal('ManageDomains', {}));
                    break;
                case 'events_manage-events':
                    dispatch(setModal('ManageEvents', {}));
                    break;
                case 'fixed-cost_manage-fixed-cost':
                    dispatch(setModal('ManageFixedCost', {}));
                    break;
                case 'goals_manage-goals':
                    dispatch(setModal('ManageGoals', {}));
                    break;
                case 'setup-above-the-line':
                    dispatch(setModal('SetupAboveTheLine', {}));
                    break;
                case 'manage-above-the-line':
                    dispatch(setModal('ManageAboveTheLine', {}));
                    break;
                case 'manage-impression-modelling':
                    dispatch(setModal('ManageImpressionModelling', {}));
                    break;
                case 'keywords_add-keywords':
                    dispatch(setModal('AddKeywords', {}));
                    break;
                case 'magento_manage-magento':
                    dispatch(setModal('ManageMagento', {}));
                    break;
                case 'manage-timezone-currency':
                    dispatch(setModal('ManageTimezoneAndCurrency', {}));
                    break;
                case 'user-management_manage-users':
                    dispatch(setModal('ManageUsers', {}));
                    break;
                case 'user-management_create-user':
                    dispatch(setModal('CreateUser', {}));
                    break;
                case 'user-management_invite-users':
                    dispatch(setModal('InviteUser', {}));
                    break;
                case 'replay_offline-sales':
                    dispatch(setModal('ReplayOfflineSales', {}));
                    break;
                case 'forecasts_add-forecasts':
                    dispatch(setModal('AddForecasts', {}));
                    break;
                case 'tv-slots_add-tv-slots':
                    dispatch(setModal('AddTVSlots', {}));
                    break;
                case 'oxylabs-url-exclude':
                    dispatch(setModal('ManageExcludedUrlsOxylabs', {}));
                    break;
                case 'toggle-crawling':
                    dispatch(setModal('ManageCrawling', {}));
                    break;
                case 'manage-seo-keywords':
                    dispatch(setModal('ManageSEOKeywords', {}));
                    break;
                case 'manage-tiktok':
                    dispatch(setModal('TiktokAPI', {}));
                    break;
                case 'integrations_manage-zendesk':
                    dispatch(setModal('ZendeskAPI', {}));
                    break;
                case 'manage-segmenter-pattern':
                    dispatch(setModal('ManageSegmenterPattern', {}));
                    break;
                case 'AKWR-automated_config_kwr':
                    dispatch(setModal('AutomatedKWRConfig', {}));
                    break;
                case 'AKWR-blacklisted-keywords':
                    dispatch(setModal('ManageBlacklistedKeywords', {}));
                    break;
                case 'AKWR-map_pages_to_market':
                    dispatch(setModal('ManagePagesToMarket', {}));
                    break;
                case 'AKWR-competitors':
                    dispatch(setModal('ManageCompetitors', {}));
                    break;
                case 'manage-attribution-models':
                    dispatch(setModal('ManageAttributionModels', {}));
                    break;
                case 'manage-conditions':
                    dispatch(setModal('SegmenterEditConditions', {}));
                    break;
                case 'manage-segments':
                    dispatch(setModal('SegmenterEditSegment', {}));
                    break;
                case 'manage-brand-split-config':
                    dispatch(setModal('BrandSplitConfig', {}));
                    break;
                case 'manage-page-type-url-subdirectories':
                    dispatch(setModal('ManagePageTypeURLSubdirectories', {}));
                    break;
                case 'manage-page-categories':
                    dispatch(setModal('ManagePageCategories', {}));
                    break;
                case 'manage-subchannels':
                    dispatch(setModal('ManageSubchannels', {}));
                    break;
                case 'page-segments_add-page-segments':
                    dispatch(setModal('AddPageSegments', {}));
                    break;
                case 'add-auto-email-config':
                    dispatch(setModal('AddAutoEmailConfig', {}));
                    break;
                default:
                    break;
            }
        },
        [dispatch]
    );

    const ingestUrl = useCallback(() => {
        try {
            const pageHash = location.hash;
            const params = location.search.substring(1).split('&');

            let type = params.filter(param => /type=/.test(param));
            let connectionId = params.filter(param => /connection=/.test(param));
            let error = params.filter(param => /error=/.test(param));

            switch (pageHash) {
                case '#import-accounts':
                    if (type.length === 0 || connectionId.length === 0) {
                        throw new Error('Incorrect Params were sent');
                    }

                    // Grab the actual value from the query string
                    type = type[0].split('=')[1];
                    connectionId = parseInt(connectionId[0].split('=')[1]);

                    dispatch(
                        addNotification({
                            copy: `A connection was successfully made to ${type}.`,
                            type: NotificationMessageType.Success,
                        })
                    );
                    dispatch(
                        setPopup({
                            title: 'Import Accounts',
                            iconType: 'informative',
                            contentType: 'importIntegrationAccounts',
                            config: {
                                technology: type,
                                connectionId: connectionId,
                            },
                            buttons: [],
                        })
                    );
                    break;

                case '#failed-connection':
                    if (type.length === 0) {
                        throw new Error('Incorrect Params were sent');
                    }

                    // Grab the actual value from the query string
                    type = type[0].split('=')[1];

                    let title = 'Issue trying to create a connection.';
                    let message = `There was an issue trying to make a connection to ${type}. Please contact us at support@cubed.email.`;

                    if (error.length === 1) {
                        error = error[0].split('=')[1];

                        switch (error) {
                            case 'unauthorised':
                                title = 'Unauthorised to make this connection';
                                message = `There was an issue trying to make a connection to ${type} as you are unauthorised to do so. Please contact us at support@cubed.email.`;
                                break;
                            case 'non-ads-account':
                                title = 'The account does not have AD Accounts setup.';
                                message = `There was an issue trying to make a connection to Google API as you have not set up ADs Account. Please contact us at support@cubed.email.`;
                                break;
                            case 'mismatched-scope':
                                title = 'The account services does not match the old Cubed record.';
                                message = `There was an issue trying to make a connection to Google API. This is probably because there was an connection established with same account with different google services. Please contact us at support@cubed.email if you need any assistance.`;
                                break;
                            case 'no-ad-account-details':
                                title = 'The TikTok Ad account does not have enough details.';
                                message = `There was an issue trying to make a connection to the TikTok API. This is probably because your ad account is not fully configured or Cubed was not granted all permission required to access the ad account information. Please contact us at support@cubed.email if you need any assistance.`;
                                break;
                            default:
                                break;
                        }
                    }

                    dispatch(
                        setPopup({
                            title: title,
                            icontype: 'error',
                            contentType: 'simple',
                            config: {
                                copy: message,
                            },
                            buttons: [
                                {
                                    onClick: () => {
                                        dispatch(removePopup());
                                    },
                                    value: 'CLOSE',
                                    style: 'secondary',
                                },
                            ],
                        })
                    );

                    dispatch(setModal(`${capitalize(type)}API`, {}));
                    break;

                case '#manage':
                    type = type[0].split('=')[1];
                    dispatch(setModal(`${capitalize(type)}API`, {}));
                    break;

                default:
                    break;
            }
        } catch (e) {
            dispatch(
                addNotification({
                    copy: 'There was an issue processing the parameters given. Please contact support@cubed.email.',
                    type: NotificationMessageType.Error,
                })
            );
        }
    }, [dispatch, location.hash, location.search]);

    let accountConfigurations = useMemo(() => {
        if (account.group === 'API') {
            return [
                {
                    header: {
                        type: 'Title',
                        title: 'Integrations',
                        keyValue: 'integrations',
                    },
                    type: 'List',
                    key: 'integrations',
                    keyValue: 'integrations',
                    rows: [
                        {
                            keyValue: 'integrations_google-api',
                            type: 'ListRowTitleCopy',
                            title: 'Unified Google',
                            copy: 'Set up and manage integrations from your Google Ads and Google Console accounts. Data from Google Ads and Google Console is used to supplement PPC and Shopping reporting.',
                            // copy: 'Set up and manage integrations from your Google Ads, Google Console and Youtube accounts. Data from Google Ads and Google Console is used to supplement PPC and Shopping reporting. Data from Youtube can be used to better match customers with Cubed data and also supplement Cubed reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_bing-api',
                            type: 'ListRowTitleCopy',
                            title: 'Bing',
                            copy: 'Set up and manage integrations from your Bing accounts. Data from Bing is used to supplement the Cubed reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_facebook-api',
                            type: 'ListRowTitleCopy',
                            title: 'Facebook',
                            copy: 'Set up and manage integrations from your Facebook accounts. Data from Facebook is used to supplement reporting on Facebook costs and Ad related data.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'magento_manage-magento',
                            type: 'ListRowTitleCopy',
                            title: 'Magento',
                            copy: 'Set up and manage integrations from your Magento accounts. Data from Magento is used to supplement sales reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_manage-zendesk',
                            type: 'ListRowTitleCopy',
                            title: 'Zendesk',
                            copy: 'Set up and manage integrations from your Zendesk accounts.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'manage-tiktok',
                            type: 'ListRowTitleCopy',
                            title: 'TikTok',
                            copy: 'Set up and manage integrations from your TikTok accounts. Data from TikTok is used to supplement sales reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_manage-klaviyo',
                            type: 'ListRowTitleCopy',
                            title: 'Klaviyo',
                            copy: 'Set up and manage integrations from your Klaviyo account(s). Data from Klaviyo can be used to better match customers with Cubed data.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_manage-pinterest',
                            type: 'ListRowTitleCopy',
                            title: 'Pinterest',
                            copy: 'Set up and manage integrations from your Pinterest account(s). Data from Pinterest can be used to better match customers with Cubed data and also supplement Cubed reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                        {
                            keyValue: 'integrations_manage-semrush',
                            type: 'ListRowTitleCopy',
                            title: 'SEMRush',
                            copy: 'Set up and manage integrations from your SEMRUSH account. Data from SEMRUSH is used to supplement SEO strategy and reporting.',
                            chevron: true,
                            onClick: listComponentOnClickHandler,
                        },
                    ],
                },
            ];
        }

        return [
            {
                header: {
                    type: 'Title',
                    title: 'Account Settings ',
                    keyValue: 'account-settings',
                },
                type: 'List',
                keyValue: 'account-settings',
                rows: [
                    {
                        keyValue: 'manage-timezone-currency',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Timezone and Currency',
                        copy: 'Configure your accounts timezone and currency settings.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'User Management',
                    keyValue: 'user-management',
                },
                type: 'List',
                keyValue: 'user-management',
                rows: [
                    {
                        keyValue: 'user-management_manage-users',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Users',
                        copy: 'Create, invite or manage existing users that have access to your account.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Events',
                    keyValue: 'events',
                },
                type: 'List',
                key: 'events',
                keyValue: 'events',
                rows: [
                    {
                        keyValue: 'events_manage-events',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Events',
                        copy: 'Create, edit or delete existing tracking events.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Goals',
                    keyValue: 'goals',
                },
                type: 'List',
                key: 'goals',
                keyValue: 'goals',
                rows: [
                    {
                        keyValue: 'goals_manage-goals',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Goals & Assign Events',
                        copy: 'Create, edit or delete existing goals and assign sale events to your goals.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Attribution Model',
                    keyValue: 'attribution-model',
                },
                type: 'List',
                key: 'attribution-model',
                keyValue: 'attribution-model',
                rows: [
                    {
                        keyValue: 'manage-attribution-models',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Attribution Models',
                        copy: 'Manage your attribution models, including setting date exclusions, a lookback window and which channels you wish to reallocate.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Integrations',
                    keyValue: 'integrations',
                },
                type: 'List',
                key: 'integrations',
                keyValue: 'integrations',
                rows: [
                    {
                        keyValue: 'integrations_google-api',
                        type: 'ListRowTitleCopy',
                        title: 'Unified Google',
                        copy: 'Set up and manage integrations from your Google Ads and Google Console accounts. Data from Google Ads and Google Console is used to supplement PPC and Shopping reporting.',
                        // copy: 'Set up and manage integrations from your Google Ads, Google Console and Youtube accounts. Data from Google Ads and Google Console is used to supplement PPC and Shopping reporting. Data from Youtube can be used to better match customers with Cubed data and also supplement Cubed reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_bing-api',
                        type: 'ListRowTitleCopy',
                        title: 'Bing',
                        copy: 'Set up and manage integrations from your Bing accounts. Data from Bing is used to supplement the Cubed reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_facebook-api',
                        type: 'ListRowTitleCopy',
                        title: 'Facebook',
                        copy: 'Set up and manage integrations from your Facebook accounts. Data from Facebook is used to supplement reporting on Facebook costs and Ad related data.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'magento_manage-magento',
                        type: 'ListRowTitleCopy',
                        title: 'Magento',
                        copy: 'Set up and manage integrations from your Magento accounts. Data from Magento is used to supplement sales reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_manage-zendesk',
                        type: 'ListRowTitleCopy',
                        title: 'Zendesk',
                        copy: 'Set up and manage integrations from your Zendesk accounts.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'manage-tiktok',
                        type: 'ListRowTitleCopy',
                        title: 'TikTok',
                        copy: 'Set up and manage integrations from your TikTok accounts. Data from TikTok is used to supplement sales reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_manage-klaviyo',
                        type: 'ListRowTitleCopy',
                        title: 'Klaviyo',
                        copy: 'Set up and manage integrations from your Klaviyo account(s). Data from Klaviyo can be used to better match customers with Cubed data.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_manage-pinterest',
                        type: 'ListRowTitleCopy',
                        title: 'Pinterest',
                        copy: 'Set up and manage integrations from your Pinterest account(s). Data from Pinterest can be used to better match customers with Cubed data and also supplement Cubed reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'integrations_manage-semrush',
                        type: 'ListRowTitleCopy',
                        title: 'SEMRush',
                        copy: 'Set up and manage integrations from your SEMRUSH account. Data from SEMRUSH is used to supplement SEO strategy and reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Channels',
                    keyValue: 'channels',
                },
                type: 'List',
                key: 'channels',
                keyValue: 'channels',
                rows: [
                    {
                        keyValue: 'channels_manage-channels',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Channels and Patterns',
                        copy: 'Create, edit or delete existing channels and pattern definitions.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'manage-subchannels',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Subchannels',
                        copy: 'Create, edit or delete existing subchannels.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'manage-above-the-line',
                        type: 'ListRowTitleCopy',
                        title: 'Manage ATL Reporting',
                        copy: 'Create, edit or delete existing ATL configurations.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'manage-impression-modelling',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Impression Reporting',
                        copy: 'Our impression reporting is built upon modelling a baseline based on the relationship impression traffic and website traffic. We model a baseline for each impression activity, such as Display, VOD or Social Impressions.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Blacklisting',
                    keyValue: 'blacklisting',
                },
                type: 'List',
                key: 'blacklisting',
                keyValue: 'blacklisting',
                rows: [
                    {
                        keyValue: 'blacklisting_manage-ip-blacklist',
                        type: 'ListRowTitleCopy',
                        title: 'Manage IP Address Blacklist Rules',
                        copy: 'Create, edit or delete existing IP address blacklist rules.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'blacklisting_manage-visitor-blacklist',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Visitor ID Blacklist Rules',
                        copy: 'Create, edit or delete existing Visitor ID blacklist rules.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Domains',
                    keyValue: 'domains',
                },
                type: 'List',
                key: 'domains',
                keyValue: 'domains',
                rows: [
                    {
                        keyValue: 'domains_manage-domains',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Domains',
                        copy: 'Create, edit or delete existing domains. ',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Fixed Cost',
                    keyValue: 'fixed-cost',
                },
                type: 'List',
                key: 'fixed-cost',
                keyValue: 'fixed-cost',
                rows: [
                    {
                        keyValue: 'fixed-cost_manage-fixed-cost',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Fixed Costs',
                        copy: 'Create, edit or delete existing fixed costs.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'External Imports',
                    keyValue: 'add-keywords',
                },
                type: 'List',
                key: 'add-keywords',
                keyValue: 'add-keywords',
                rows: [
                    {
                        keyValue: 'keywords_add-keywords',
                        type: 'ListRowTitleCopy',
                        title: 'SEO Keywords',
                        copy: 'Import your keyword research data into Cubed to enable advanced SEO reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'forecasts_add-forecasts',
                        type: 'ListRowTitleCopy',
                        title: 'Forecasts and Budgets',
                        copy: 'Import forecasted traffic, sales and revenue by channel and date to allow use of Performance V Forecast Report.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'tv-slots_add-tv-slots',
                        type: 'ListRowTitleCopy',
                        title: 'Add TV Slots',
                        copy: 'Import your TV slot data to enable advanced ATL reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'page-segments_add-page-segments',
                        type: 'ListRowTitleCopy',
                        title: 'Add Page Type Groupings',
                        copy: 'Import your page type groupings into Cubed to enable advanced content reporting.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    // {
                    //     keyValue: 'replay_offline-sales',
                    //     type: 'ListRowTitleCopy',
                    //     title: 'Replay Offline Sales',
                    //     copy: 'Import your offline sales data into Cubed and use our syncing functionality to join with onsite visitors.',
                    //     chevron: true,
                    //     onClick: listComponentOnClickHandler,
                    // },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'SEO Keywords',
                    keyValue: 'seo-keywords',
                },
                type: 'List',
                key: 'seo-keywords',
                keyValue: 'seo-keywords',
                rows: [
                    {
                        keyValue: 'manage-seo-keywords',
                        type: 'ListRowTitleCopy',
                        title: 'Manage SEO Keywords',
                        copy: 'Manage your SEO keywords, including updating keyword categorisations and mapped pages.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Automated Keyword Research',
                    keyValue: 'automated-keyword-research',
                },
                type: 'List',
                key: 'automated-keyword-research',
                keyValue: 'automated-keyword-research',
                rows: [
                    {
                        keyValue: 'AKWR-automated_config_kwr',
                        type: 'ListRowTitleCopy',
                        title: 'Automated KWR Configuration',
                        copy: 'Configure conditions for automated keyword research, including specific topical keyword research for desired markets and languages, or, a more general automated keyword research across the your whole website.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'AKWR-blacklisted-keywords',
                        type: 'ListRowTitleCopy',
                        title: 'Blacklisted Keywords',
                        copy: 'Add or update keywords which you would like to blacklist from the automated keyword research process.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'AKWR-map_pages_to_market',
                        type: 'ListRowTitleCopy',
                        title: 'Map Pages to Markets',
                        copy: 'Map your pages to Markets to support automated KWR.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'AKWR-competitors',
                        type: 'ListRowTitleCopy',
                        title: 'Competitors',
                        copy: `Add the competitors you'd like us to use to further optimize the automated KWR process.`,
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Web Crawler',
                    keyValue: 'start-web-scraping',
                },
                type: 'List',
                key: 'start-web-scraping',
                keyValue: 'start-web-scraping',
                rows: [
                    {
                        keyValue: 'toggle-crawling',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Crawl or Spider',
                        copy: 'Manage crawling or spidering your website.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'oxylabs-url-exclude',
                        type: 'ListRowTitleCopy',
                        title: 'Manage URL Exclusion List',
                        copy: 'The URL regex exclusion list is used to identify pages which you would like us not to crawl. You can add or remove patterns at any time.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Advanced Rule Builder',
                    keyValue: 'advanced-rule-builder',
                },
                type: 'List',
                key: 'advanced-rule-builder',
                keyValue: 'advanced-rule-builder',
                rows: [
                    {
                        keyValue: 'manage-segmenter-pattern',
                        type: 'ListRowTitleCopy',
                        title: 'Patterns',
                        copy: 'Set the pattern of a visit, by configuring a rule using a combination of pre-existing conditions. ',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Segments',
                    keyValue: 'segments',
                },
                type: 'List',
                key: 'segments',
                keyValue: 'segments',
                rows: [
                    {
                        keyValue: 'manage-conditions',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Conditions',
                        copy: 'Create, edit or delete segment conditions.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'manage-segments',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Segments',
                        copy: 'Create, edit or delete segments.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            {
                header: {
                    type: 'Title',
                    title: 'Brands Awareness',
                    keyValue: 'brand-awareness',
                },
                type: 'List',
                key: 'brand-awareness',
                keyValue: 'brand-awareness',
                rows: [
                    {
                        keyValue: 'manage-brand-split-config',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Brand Split Config',
                        copy: 'Create, edit or delete brand split config.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
            // {
            //     header: {
            //         type: 'Title',
            //         title: 'Page Types',
            //         keyValue: 'page-types',
            //     },
            //     type: 'List',
            //     key: 'page-types',
            //     keyValue: 'page-types',
            //     rows: [
            //         {
            //             keyValue: 'manage-page-type-url-subdirectories',
            //             type: 'ListRowTitleCopy',
            //             title: 'Manage Page Type - URL Subdirectories',
            //             copy: 'Manage your Page Types by configuring your URL subdirectories into groups.',
            //             chevron: true,
            //             onClick: listComponentOnClickHandler,
            //         },
            //         {
            //             keyValue: 'manage-page-categories',
            //             type: 'ListRowTitleCopy',
            //             title: 'Manage Page Categories',
            //             copy: 'Manage your Page Categories by configuring your urls into categories using regex rules.',
            //             chevron: true,
            //             onClick: listComponentOnClickHandler,
            //         },
            //     ],
            // },
            {
                header: {
                    type: 'Title',
                    title: 'Automated Email Configuration',
                    keyValue: 'automated-email-configuration',
                },
                type: 'List',
                key: 'automated-email-configuration',
                keyValue: 'automated-email-configuration',
                rows: [
                    {
                        keyValue: 'add-auto-email-config',
                        type: 'ListRowTitleCopy',
                        title: 'Manage Automated Email Report Configuration',
                        copy: 'Create, edit or delete automated email report configurations.',
                        chevron: true,
                        onClick: listComponentOnClickHandler,
                    },
                ],
            },
        ];
    }, [account.group]);

    useEffect(() => {
        setListErrorMessageOverride(
            'The search term you have entered does not match with any of the Account Configurations.'
        );
        setSearchValue('');
        setAllAccountConfigurations(accountConfigurations);
        setVisibleAccountConfigurations(accountConfigurations);
        ingestUrl();
    }, [ingestUrl]);

    const capitalize = s => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const sideBarMenuOnClickHandler = e => {
        e.stopPropagation();

        const selectedKey = e.currentTarget.getAttribute('data-key');

        if (selectedKey !== '') {
            const itemInList = document.querySelector(`.list-widget [data-key="${selectedKey}"]`);
            const itemInListPosition = itemInList.offsetTop;

            const scrollElement =
                window.document.scrollingElement || window.document.body || window.document.documentElement;

            AnimeJS({
                targets: scrollElement,
                scrollTop: itemInListPosition - 200,
                duration: 500,
                easing: 'easeInOutQuad',
            });

            if (prevItem) {
                prevItem.style.backgroundColor = 'white'; // eslint-disable-line react/no-direct-mutation-state
                AnimeJS.remove(prevItem);
            }

            AnimeJS({
                targets: itemInList,
                backgroundColor: ['#FF7300', '#e6e6e6'],
                duration: 1500,
                easing: 'easeInOutQuad',
            });
            setPrevItem(itemInList);
        }
    };

    const breadCrumbHomeOnClickHandler = e => {
        setVisibleAccountConfigurations(allAccountConfigurations);
        setSearchValue('');
    };

    const searchBarOnChange = e => {
        const searchValue = e.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        const visibleConfigsAfterSearch = [];

        allAccountConfigurations.forEach(item => {
            const rowsWithSearchTerm = item.rows
                .filter(row => {
                    return searchValueRegex.test(row.title) || searchValueRegex.test(row.copy);
                })
                .map(row => {
                    return row;
                });

            if (rowsWithSearchTerm.length > 0 || item.header.title.includes(searchValue)) {
                visibleConfigsAfterSearch.push(item);
            }
        });

        setSearchValue(searchValue);
        setVisibleAccountConfigurations(visibleConfigsAfterSearch);
    };

    return (
        <div className="account-configuration">
            <div className="account-configuration__sidebar-container">
                <div className="account-configuration__side-bar">
                    <SearchBreadcrumbsWidget
                        onSearchChange={searchBarOnChange}
                        homeOnClick={breadCrumbHomeOnClickHandler}
                        searchValue={searchValue}
                    />
                    {(visibleAccountConfigurations.length > 0 && (
                        <SidebarMenu rows={visibleAccountConfigurations} onClick={sideBarMenuOnClickHandler} />
                    )) ||
                        'No items found'}
                </div>
            </div>

            <div className="account-configuration__list">
                <List rows={visibleAccountConfigurations} header={{}} errorMessageOverride={listErrorMessageOverride} />
            </div>
        </div>
    );
};

export default LayoutAccountConfiguration;
