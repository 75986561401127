import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import validators from '../../../components/forms/validation/validators';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import ConfigTable from '../../../components/tables/config-table/config-table';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../../react-query/hooks/use-delete-resource';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';
import Form from '../../../components/forms/form';

// Config
import { CONFIG_DOMAINS } from '../../../configurations/resources-config';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type Domain = {
    id: number;
    domain: string;
    selected: boolean;
};

const LayoutModalManageDomains = () => {
    const dispatch = useDispatch();

    const [domains, setDomains] = useState<Domain[]>([]);
    const [domainToDelete, setDomainToDelete] = useState<number>();
    const [domainToEdit, setDomainToEdit] = useState<Domain>();

    // Queries
    const domainQuery = useFetchResource({
        resource: CONFIG_DOMAINS,
        params: [
            {
                key: 'active',
                value: 1,
            },
        ],
        select: (data: ConfigDataSuccess) =>
            data.objects.map(domain => {
                return {
                    id: domain.id,
                    domain: domain.domain,
                    selected: false,
                };
            }),
    });

    useEffect(() => {
        if (domainQuery.data) {
            setDomains(domainQuery.data);
        }
    }, [domainQuery.data]);

    // Mutations
    const domainBulkDeleteMutation = useDeleteResource({
        resource: CONFIG_DOMAINS,
        resourceIds: domains.filter(domain => domain.selected).map(domain => String(domain.id)),
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Domains removed successfully', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while removing these domains. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const domainSingleDeleteMutation = useDeleteResource({
        resource: CONFIG_DOMAINS,
        resourceIds: [String(domainToDelete)],
        handleOnSuccess: () => {
            setDomainToDelete(undefined);
            dispatch(addNotification({ copy: 'Domain removed successfully', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while removing this domain. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const domainPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_DOMAINS,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Domain updated successfully', type: 'success' }));
            setDomainToEdit(undefined);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while updating this domain. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setDomains(
            domains.map(domain => {
                if (domain.id === rowId) {
                    return {
                        ...domain,
                        selected: !domain.selected,
                    };
                }
                return domain;
            })
        );
    };

    // Bulk Delete
    const handleBulkDelete = () => {
        dispatch(removePopup());
        domainBulkDeleteMutation.mutate();
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Remove Domains',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove these domains?',
                },
                buttons: [
                    {
                        onClick: handleBulkDelete,
                        value: 'Yes, Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Single Delete
    const handleSingleDelete = (rowId: number) => {
        dispatch(removePopup());
        domainSingleDeleteMutation.mutate();
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setDomainToDelete(rowId);

        dispatch(
            setPopup({
                title: 'Remove Domain',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove this domain?',
                },
                buttons: [
                    {
                        onClick: () => handleSingleDelete(rowId),
                        value: 'Yes, Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleFormSubmit = (data: FieldValues) => {
        domainPatchMutation.mutate({
            resourceId: [String(domainToEdit?.id) || ''],
            payload: [
                {
                    domain: data.domain,
                },
            ],
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),

                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Manage Domains</h2>

            <ConfigTable
                status={domainQuery.status}
                isFetching={
                    domainQuery.isFetching || domainBulkDeleteMutation.isPending || domainSingleDeleteMutation.isPending
                }
                disabled={domainBulkDeleteMutation.isPending || domainSingleDeleteMutation.isPending}
                empty={domainQuery.data?.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key="domain-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Domain</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {domains.map(domain => {
                            return (
                                <ConfigTable.Row key={domain.id}>
                                    <ConfigTable.CellCheckbox
                                        rowId={domain.id}
                                        checked={domain.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <ConfigTable.Cell>{domain.domain}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={domain.id}
                                            type="edit"
                                            onClick={() => setDomainToEdit(domain)}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={domain.id}
                                            type="delete"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton
                            type="delete"
                            label="Remove Domains"
                            isDisabled={domains.filter(domain => domain.selected).length === 0}
                            onClick={handleBulkDeleteClick}
                        />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="add"
                            label="Add Domain"
                            onClick={() => dispatch(setModal('AddDomain', {}))}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {domainToEdit && (
                <StyledEditContainer>
                    <h2>Edit Domain</h2>

                    <Form
                        onSubmit={handleFormSubmit}
                        defaultValues={{ domain: domainToEdit.domain }}
                        status={domainPatchMutation.isPending ? 'disabled' : 'enabled'}
                    >
                        <Form.Body>
                            <Form.Section>
                                <Form.Field>
                                    <Form.InputLabel
                                        htmlFor="domain"
                                        label="Domain"
                                        tooltipCopy="Enter the domain name that the Cubed Tag will be firing on."
                                    />
                                    <Form.InputText name="domain" validators={[validators.required]} disabled={false} />
                                </Form.Field>
                            </Form.Section>
                        </Form.Body>
                        <Form.Footer>
                            <Form.InputButton type="submit" value="Save" loading={domainPatchMutation.isPending} />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                onClick={() => setDomainToEdit(undefined)}
                                buttonTheme={ButtonThemes.Secondary}
                            />
                        </Form.Footer>
                    </Form>
                </StyledEditContainer>
            )}
        </>
    );
};

export default LayoutModalManageDomains;
