import React from 'react';
import Select, { ControlProps, GroupBase, MultiValue, SingleValue, StylesConfig } from 'react-select';

// Context
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components & Types
import { DropdownOption } from '../../../section-dashboard/types';
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const customSelectStyles: StylesConfig<DropdownOption, true> = {
    control: (provided, state: ControlProps<DropdownOption, true, GroupBase<DropdownOption>>) => ({
        ...provided,
        fontFamily: 'inherit',
        fontSize: '0.9em',
        width: '100%',
        backgroundColor: '#e9f0f5',
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0',
        borderRight: '5px solid transparent',
        borderBottom: state.isDisabled ? 'solid 2px #bfbfbf' : 'solid 2px #333',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:hover': {
            borderBottom: 'solid 2px #333',
        },
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
            border: 'none',
            borderBottom: 'solid 2px #333',
        },
    }),
    placeholder: provided => ({
        ...provided,
        fontWeight: '400',
        fontSize: '0.9em',
    }),
    option: provided => ({
        ...provided,
        fontFamily: 'inherit',
        fontSize: '0.9em',
    }),
    input: provided => ({
        ...provided,
        boxShadow: 'none',
        '&:focus': {
            boxShadow: 'none',
        },
    }),
};

export type InputSelectProps = {
    name: string;
    options: DropdownOption[];
    placeholder?: string;
    disabled?: boolean;
    validators?: Validator[];
    isMulti?: boolean;
};

const InputMultiSelect = ({
    name,
    options,
    placeholder = 'Select an option',
    disabled,
    validators = [],
    isMulti = false,
}: InputSelectProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <InputContainer>
            <Controller
                control={control}
                defaultValue={options.find(option => option.value)}
                {...register(name, { ...buildValidators(validators) })}
                render={({ field: { onChange, onBlur, ref, value, name } }) => (
                    <Select
                        inputId={name}
                        ref={ref}
                        options={options}
                        onChange={(value: MultiValue<any> | SingleValue<any>) => {
                            if (Array.isArray(value)) {
                                onChange(value.map(v => v));
                            } else {
                                onChange(value.value);
                            }
                        }}
                        isMulti={isMulti || undefined}
                        onBlur={onBlur}
                        value={isMulti && Array.isArray(value) ? value : options.find(option => option.value === value)}
                        name={name}
                        styles={customSelectStyles}
                        placeholder={placeholder}
                        isDisabled={disabled || status === 'disabled' || status === 'loading'}
                    />
                )}
            />

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputMultiSelect;
