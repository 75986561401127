const validators = {
    required: {
        required: 'This field is required',
    },
    min: (value: number) => {
        return {
            min: {
                value: value,
                message: `The minimum value is ${value}`,
            },
        };
    },
    max: (value: number) => {
        return {
            max: {
                value: value,
                message: `The maximum value is ${value}`,
            },
        };
    },
    minLength: (value: number) => {
        return {
            minLength: {
                value: value,
                message: `This field must contain at least ${value} characters`,
            },
        };
    },
    maxLength: (value: number) => {
        return {
            maxLength: {
                value: value,
                message: `This field must contain no more than ${value} characters`,
            },
        };
    },
    isAlphaNumericString: {
        validate: (value: string) => {
            return !/[^a-zA-Z\d\s:]/.test(value) || 'This field should contain only alphanumeric characters';
        },
    },
    isAlphaString: {
        validate: (value: string) => {
            return !/[^a-zA-Z\s:]/.test(value) || 'This field should contain only alphabetic characters';
        },
    },
    isEmail: {
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Invalid email address',
        },
    },
    isEmailList: {
        validate: (value: string) => {
            if (value && value.length > 0) {
                return (
                    value
                        .split(',')
                        .every(email =>
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                email.trim()
                            )
                        ) || 'This field seems to contain an invalid email address'
                );
            }
        },
    },
    isDomain: {
        pattern: {
            value: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
            message: 'Invalid domain',
        },
    },
    isNumber: {
        validate: (value: string) => {
            return !isNaN(Number(value)) || 'This field should contain only numbers';
        },
    },
    isFloat: {
        validate: (value: string) => {
            return !isNaN(parseFloat(value)) || 'This field should contain only decimal numbers';
        },
    },
    isInteger: {
        validate: (value: string) => {
            return (!isNaN(parseInt(value)) && +value % 1 === 0) || 'This field should contain only whole numbers';
        },
    },
    isPrice: (customMessage?: string) => {
        return {
            validate: (value: string) => {
                return (
                    (!isNaN(Number(value)) && /^\d{1,}.\d{2}$|^\d{1,}$/.test(value)) ||
                    customMessage ||
                    'Please enter a valid monetary value'
                );
            },
        };
    },
    isDate: {
        validate: (value: string) => {
            return (
                /^[0-3][0-9][/|-][0-1][0-9][/|-][0-9]{4}?|^[0-9]{4}[/|-][0-1][0-9][/|-][0-3][0-9]?/.test(value) ||
                'Please select a valid date'
            );
        },
    },
    isPassword: {
        validate: (value: string) => {
            return (
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#^?&])[A-Za-z\d@$!%*#^?&]{8,}$/.test(value) ||
                'Please enter a valid password'
            );
        },
    },
    passwordsMatch: {
        validate: (value: string, values: { [key: string]: string }) => {
            return value === values['password'] || 'Passwords do not match';
        },
    },
    isIP: {
        validate: (value: string) => {
            return (
                /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                    value
                ) || 'Please enter a valid IP address'
            );
        },
    },
    isURL: {
        validate: (value: string) => {
            return (
                /^([a-z]+\\:\/{2})?([\w-]+\.[\w-]+\.\w+)$|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/.test(
                    value
                ) || 'Please enter a valid URL'
            );
        },
    },
};

export default validators;
