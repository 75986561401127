/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import Papa, { ParseResult } from 'papaparse';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { RootState } from '../../redux/reducers/core';

// Components
import ModalNavigation from '../../components/modal-navigation';
import InputButton from '../../components/inputs/input-button';
import ProgressBar from '../../components/progress-bar';
import WarningMessage from '../../components/warning-message';
import FormFileUpload from '../../components/form-fields/form-file-upload';

// Helpers
import { generateUrl } from '../../helpers/request-builder';
import LoadingSpinner from '../../components/loading-spinner';

// Resources
import {
    CONFIG_PAGE_SEGMENTS_URLS_RESOURCE,
    PAGE_SEGMENTS_FILE_UPLOAD_RESOURCE,
} from '../../configurations/resources-config';

// Queries
import useCSVUploadResource from '../../react-query/hooks/use-csv-upload-resource';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';

// Helpers
import { validateFileType, validatePageTypeGroupingCsvFile } from '../../helpers/validate-csv-file';
import WidgetBaseLoader from '../../section-dashboard/widgets/base/widget-base-loader';

const StyledContainer = styled.div`
    margin-top: 20px;
    padding: 1px 20px 20px 20px;
    margin-right: auto;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

const StyledUploadSegmentButton = styled.div`
    margin-top: 7px;
    margin-left: 10px;
`;
const StyledContainerDivider = styled.div`
    height: 0.8px;
    background: grey;
    width: 100%;
    opacity: 0.2;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const StyledItemContainer = styled.div`
    padding: 5px;
`;

const StyledButtonWithMargin = styled.div`
    margin-top: 20px;
`;

const StyledValidationLoader = styled.div`
    display: flex;
    flex-gap: 10px;
    margin-top: 2px;
`;
const StyledValidationLabel = styled.p`
    justify-content: center;
    text-align: justify;
    align-content: center;
    margin-left: 5px;
    margin-bottom: 20px;
    font-weight: 500;
`;

type PageSegment = {
    category: string;
    content_length: string;
    content_type: string;
    geography: string;
    homepage: string;
    language: string;
    market: string;
    topic: string;
    url: string;
};

type CSVHeaders = [
    'category',
    'content_length',
    'content_type',
    'geography',
    'homepage',
    'language',
    'market',
    'topic',
    'url'
];

const LayoutModalAddPageSegments = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileValidationMessage, setFileValidationMessage] = useState('');
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState(false);
    const [exportedUrlSet, setExportedUrlSet] = useState<Set<string>>(new Set()); // Set performs better than array for checking if a value exists - O(1) vs O(n)
    const [loadingURLSet, setLoadingURLSet] = useState(true);

    const accountId: string = useSelector((state: RootState) => state.account.id);
    const accountToken: string = useSelector((state: RootState) => state.account.token);

    const [validationInProgress, setValidationInProgress] = useState(false);

    const oneMBChunkSize = 1048576; //1024 * 1024;

    const dispatch = useDispatch();

    const {
        isLoading,
        isUploading,
        uploadComplete,
        uploadProgress,
        returningMessage,
        uploadError,
        pageError,
        resetStateFunction,
    } = useCSVUploadResource({
        fileUploadProgressResource: PAGE_SEGMENTS_FILE_UPLOAD_RESOURCE,
        formData,
    });

    const exportedUrlQuery = useFetchResource({
        resource: CONFIG_PAGE_SEGMENTS_URLS_RESOURCE,
        params: [{ key: 'pagination', value: false }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(data => {
                return {
                    ...data,
                };
            });
        },
    });

    useEffect(() => {
        if (exportedUrlQuery.data && exportedUrlQuery.data.length > 0) {
            setExportedUrlSet(new Set(exportedUrlQuery.data.map(data => data.url)));
        } else {
            setExportedUrlSet(new Set());
        }
        if (exportedUrlQuery.isSuccess) {
            setLoadingURLSet(false);
        }
    }, [exportedUrlQuery.data]);

    const onUploadPageSegmentsCSVClick = () => {
        const data = new FormData();
        data.append('upload_type', 'page_segments');

        const blob = new Blob([selectedFile!], { type: selectedFile?.type });
        data.append('file', blob);
        data.append('account_id', accountId);

        setFormData(data);
    };

    const downloadPageSegmentsCSV = async () => {
        setIsDownloading(true);
        const downloadUrl = generateUrl(
            'config',
            'page-segments-csv',
            [
                { key: 'limit', value: 0 },
                { key: 'format', value: 'csv' },
            ],
            true
        )!;
        const filename = `page_segments-${accountToken}.csv`;
        await Axios({
            method: 'GET',
            url: downloadUrl,
            responseType: 'blob',
            withCredentials: true,
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            })
            .catch(() => {
                setIsDownloading(false);
                dispatch(addNotification({ copy: 'Export has failed.', type: NotificationMessageType.Error }));
            });
    };
    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileValidationMessage('');
        setSelectedFileIsValid(false);
        setUploadButtonDisabled(true);
        setValidationInProgress(true);

        const file = event.target.files?.[0] as File;

        // Check the file extension
        const { isValid, selectedFile, message } = validateFileType(file, 'csv');

        if (!isValid) {
            setSelectedFileIsValid(isValid);
            setSelectedFile(selectedFile);
            setFileValidationMessage(message);

            return;
        }

        let headerChecked = false;
        let validationPassed = true;
        try {
            Papa.parse(file, {
                skipEmptyLines: true,
                header: true,
                chunkSize: oneMBChunkSize,
                chunk: (result, parser) => {
                    if (result.meta.fields === undefined) {
                        setSelectedFile(null);
                        setSelectedFileIsValid(false);
                        setFileValidationMessage(
                            "Sorry, this file doesn't appear to have data. Please check it and try again."
                        );
                        validationPassed = false;
                        parser.abort();
                        return;
                    }

                    if (!headerChecked) {
                        if (!validateCSVFileHeaders([...result.meta.fields])) {
                            setSelectedFile(null);
                            setSelectedFileIsValid(false);
                            validationPassed = false;
                            parser.abort();
                            return;
                        }
                        headerChecked = true;
                    }

                    const { isValidated, fileValidationMessage } = validatePageTypeGroupingCsvFile(
                        result.meta.fields as CSVHeaders,
                        result.data as PageSegment[],
                        exportedUrlSet
                    );

                    setFileValidationMessage(fileValidationMessage);

                    if (!isValidated) {
                        setSelectedFileIsValid(false);
                        setSelectedFile(null);
                        validationPassed = false;
                        parser.abort();
                    }
                },
                complete: (results: ParseResult<Record<string, unknown>>, file: File) => {
                    // eslint-disable-line @typescript-eslint/no-unused-vars
                    setValidationInProgress(false);
                    if (validationPassed) {
                        setSelectedFileIsValid(true);
                        setUploadButtonDisabled(false);
                        setSelectedFile(file);
                    }
                },
            });
        } catch (error) {
            console.error(error);
            setSelectedFile(null);
            setSelectedFileIsValid(false);
            setFileValidationMessage('Sorry, there was a problem reading this file. Please check it and try again.');
        }
    };

    const validateCSVFileHeaders = (headerData: string[]) => {
        const sortedHeaders = [...headerData].sort();

        const preSortedFileHeaders = [
            'category',
            'content_length',
            'content_type',
            'geography',
            'homepage',
            'language',
            'market',
            'topic',
            'url',
        ];
        if (sortedHeaders.length !== preSortedFileHeaders.length) {
            setFileValidationMessage('File validation failed : The file does not contain correct number of columns.');
            return false;
        }
        for (let i = 0; i < sortedHeaders.length; i++) {
            if (sortedHeaders[i] !== preSortedFileHeaders[i]) {
                setFileValidationMessage('File validation failed : The file does not contain correct columns.');
                return false;
            }
        }
        return true;
    };

    const cancelOrFinishUpload = () => {
        setIsDownloading(false);
        setFileValidationMessage('');
        setUploadButtonDisabled(true);
        setSelectedFile(null);
        setSelectedFileIsValid(false);
        resetStateFunction();
    };

    const PageLayout = ({ children }: { children: React.ReactNode }) => {
        const dispatch = useDispatch();

        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: () => dispatch(removeModal()),
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return (
            <div>
                <ModalNavigation buttons={modalNavigationButtons} />
                <h2>Add or Export Page Type Groupings</h2>
                <p>
                    Import your page type groupings data into Cubed to enable advanced content reporting. It is
                    important to use the defined CSV format - you can download it{' '}
                    <a href="/files/page_segments.csv">here</a>.
                </p>
                {children}
            </div>
        );
    };

    const ValidationLoader = () => {
        return (
            <StyledValidationLoader>
                <WidgetBaseLoader width={30} height={30} margin={0} />
                <StyledValidationLabel>Validating File</StyledValidationLabel>
            </StyledValidationLoader>
        );
    };

    const UploadSegment = () => {
        return (
            <StyledItemContainer>
                <h3>Upload Page Type Groupings</h3>
                <StyledButtonContainer>
                    <FormFileUpload
                        onChange={onFileInputChange}
                        inputKeyValue={'add-page-segment_file-upload'}
                        errorMessage={fileValidationMessage}
                        isValid={selectedFileIsValid}
                        uploadedFile={selectedFile?.name}
                    />
                    {validationInProgress ? (
                        <ValidationLoader />
                    ) : (
                        <>
                            <StyledUploadSegmentButton>
                                <InputButton
                                    buttonTheme={ButtonThemes.Primary}
                                    value={'Upload'}
                                    onClick={onUploadPageSegmentsCSVClick}
                                    disabled={uploadButtonDisabled}
                                    hidden={!selectedFileIsValid}
                                />
                            </StyledUploadSegmentButton>
                            <StyledUploadSegmentButton>
                                <InputButton
                                    buttonTheme={ButtonThemes.Primary}
                                    value={'Cancel'}
                                    onClick={cancelOrFinishUpload}
                                    disabled={uploadButtonDisabled}
                                    hidden={!selectedFileIsValid}
                                />
                            </StyledUploadSegmentButton>
                        </>
                    )}
                </StyledButtonContainer>
            </StyledItemContainer>
        );
    };

    const DownloadSegment = () => {
        return (
            <StyledItemContainer>
                <h3>Export Page Type Groupings</h3>
                <StyledButtonContainer>
                    <InputButton value={'Download'} onClick={downloadPageSegmentsCSV} isLoading={isDownloading} />
                </StyledButtonContainer>
            </StyledItemContainer>
        );
    };

    const HomePage = () => {
        return (
            <>
                <UploadSegment />
                <StyledContainerDivider />
                <DownloadSegment />
            </>
        );
    };
    const UploadProgressBar = () => {
        return (
            <div className="file-upload-progress">
                {returningMessage && <p>{returningMessage}</p>}
                <p>{'Uploading page segments.'}</p>
                <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                <StyledButtonWithMargin>
                    <InputButton
                        buttonTheme={ButtonThemes.Primary}
                        value={'Done'}
                        hidden={uploadProgress !== '100.00'}
                        onClick={cancelOrFinishUpload}
                    />
                </StyledButtonWithMargin>
            </div>
        );
    };
    const Error = () => {
        const message = returningMessage
            ? returningMessage
            : 'There was a server issue getting this page ready. Please try again later or contact support@cubed.com.';
        return <WarningMessage copy={message} />;
    };

    return (
        <PageLayout>
            <StyledContainer>
                {isLoading || loadingURLSet ? (
                    <LoadingSpinner />
                ) : (isUploading || uploadComplete) && !pageError ? (
                    <UploadProgressBar />
                ) : pageError || uploadError ? (
                    <Error />
                ) : (
                    <HomePage />
                )}
            </StyledContainer>
        </PageLayout>
    );
};

export default LayoutModalAddPageSegments;
