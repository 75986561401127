import React, { useEffect } from 'react';
import styled from 'styled-components';
import { inputStyles } from './styles/input-styles';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';
import IconClose from '../../icons/close-icon';

const StyledInput = styled.input<{ disabled: boolean }>`
    ${inputStyles}
`;

const StyledTagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
`;

const StyledTag = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    padding: 5px 10px;
    background-color: ${props => props.theme.colours.lightGrey};
    border-radius: 10px;
`;

const StyledRemove = styled.div`
    padding: 0px 5px;
    cursor: pointer;
`;

const StyledIconClose = styled(IconClose)`
    width: 8px;
    position: relative;
    top: 2px;
`;

export type InputTagProps = {
    name: string;
    placeholder?: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputTag = ({ name, placeholder, disabled, validators = [] }: InputTagProps) => {
    const { status } = useCubedFormContext();
    const {
        watch,
        register,
        setValue,
        trigger,
        resetField,
        formState: { errors },
    } = useFormContext();

    const formValues = watch();

    useEffect(() => {
        if (formValues[`tags-${name}`] === '') {
            resetField(`tags-${name}`);
        }
    });

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        // Trigger validation
        trigger(`tags-${name}`).then(isValid => {
            const value = formValues[`tags-${name}`];
            if (value && value !== '') {
                if (isValid) {
                    if (!formValues[name]) {
                        setValue(name, [...value.split(',').map((tag: string) => tag.trim())]);
                    } else {
                        setValue(name, [...formValues[name], ...value.split(',').map((tag: string) => tag.trim())]);
                    }

                    setValue(`tags-${name}`, '');
                }
            }
        });
    };

    const handleRemoveTag = (tag: string) => {
        setValue(
            name,
            formValues[name].filter((value: string) => value !== tag)
        );
        resetField(`tags-${name}`);
    };

    return (
        <InputContainer>
            <StyledInput
                type="text"
                placeholder={placeholder}
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                onKeyDown={event => event.key === 'Enter' && handleEnterPress(event)}
                {...register(`tags-${name}`, {
                    validate: {
                        ...buildValidators(validators),
                        noDuplicate: value => {
                            if (value && value.length > 0) {
                                const values = value.split(',').map((tag: string) => tag.trim());
                                const uniqueValues = new Set(values);

                                return (
                                    (!values.some((value: string) => formValues[name]?.includes(value)) &&
                                        uniqueValues.size === values.length) ||
                                    'This field contains a duplicate value'
                                );
                            }
                        },
                    },
                })}
            />

            {errors[`tags-${name}`] && <InputErrorMessage error={errors[`tags-${name}`] as FieldError} />}

            {formValues[name] ? (
                <StyledTagContainer>
                    {formValues[name].map((tag: string) => {
                        return (
                            <StyledTag key={tag}>
                                {tag}
                                <StyledRemove onClick={() => handleRemoveTag(tag)}>
                                    <StyledIconClose />
                                </StyledRemove>
                            </StyledTag>
                        );
                    })}
                </StyledTagContainer>
            ) : null}
        </InputContainer>
    );
};

export default InputTag;
