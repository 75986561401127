// Helpers
import dateStringToUnix from '../../helpers/date-string-to-unix';
import { getRawValueAsNumber, getValue, getRawValueAsString } from './resource-data';

// Types
import { CubedField } from '../../types';
import { ResourceData, WidgetTheme } from '../types';
import { ResourceDataObject } from '../../react-query/types';

// Configuration
import { alternativeGraphColours, graphColours } from './graph-colours';

/* 
    Highchart venn diagram has a precision level set for plotting data points (union, intersection, disjoints).
    We ran into an issue when the data were too small to be plotted in the graph. This led to an error causing the whole page
    to not render. The range of values passed to the venn diagram and the number of data points could be
    the culprit here, because scaling the data fixes the issue. They use various algorithm to determine how to plot the circle.
    The error was when bisect algorithm tried to approximate the root in distance equation. If the value of the metrics are too
    small compared to other values, the distance between the points becomes too small and the bisect algorithm fails to approximate
    the root of the equation. To fix this, we scale the value of the metric by below factor for the venn diagram.
    The scaled value needs to be down scaled in the tooltip where the venn data is consumed from this source.
*/
export const VENN_CHART_SCALE_FACTOR = 100;

export const vennChartDataSeries = (
    dimensionResourceData: ResourceData,
    resourceData: ResourceData,
    metric: CubedField
) => {
    let sets: never | { id: number; name: string }[] = [];
    let data: never | { sets: string[]; value: number; color?: string; themeColour?: WidgetTheme }[] = [];

    if (dimensionResourceData && 'objects' in dimensionResourceData) {
        sets = dimensionResourceData.objects.map(object => {
            return {
                id: getRawValueAsNumber(object, 'id'),
                name: getRawValueAsString(object, 'name'),
            };
        });
    }

    if (resourceData && 'objects' in resourceData) {
        const colours = [...graphColours, ...alternativeGraphColours];
        data = resourceData.objects.map((object, index) => {
            const combination = getRawValueAsString(object, 'combination')
                .trim()
                .replace('(', '')
                .replace(')', '')
                .split(',');

            return {
                sets: combination.map(item => {
                    const name = sets.find(set => set.id === parseInt(item))?.name;
                    if (name) return name;
                    return 'Unknown';
                }),
                value: getRawValueAsNumber(object, metric.rawName) * VENN_CHART_SCALE_FACTOR,
                color: colours[index % colours.length].solidGraphColour,
                themeColour: colours[index % colours.length],
            };
        });
    }

    return data;
};

export const sparkLineDataSeries = (
    dataObjects: ResourceDataObject[],
    metric: CubedField,
    dateDimension: CubedField
) => {
    const series: [number, number][] = [];
    dataObjects.forEach(data => {
        const rawValue = getRawValueAsNumber(data, metric.rawName);
        series.push([dateStringToUnix(getValue(data, dateDimension.rawName)), rawValue]);
    });

    return series;
};

export const histogramChartDataSeries = (data: ResourceData, metric: CubedField, category: CubedField) => {
    let histogramData: { __id: string; name: string; y: number; colour: any }[] = [];

    if (data && 'objects' in data) {
        histogramData = data.objects.map((data, index) => {
            return {
                __id: data.__id,
                name: getValue(data, category.rawName),
                y: getRawValueAsNumber(data, metric.rawName),
                colour: graphColours[index % graphColours.length].solidGraphColour,
            };
        });
    }

    return histogramData;
};
